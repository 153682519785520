import { ChangeDetectorRef, Component } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';
import { User } from 'src/app/classes/credentials/User.class';
import { waitForInteropAuthenticate } from 'src/app/modules/interop/helpers';
import { DataService } from 'src/app/services/data/data.service';
import { environment } from 'src/environments/environment';
import { ViewBaseComponent } from '../../../../components/views/view-base.component';
import { ViewsComponent } from '../../../../components/views/views.component';
import { CredentialsService } from '../../../../services/credentials/credentials.service';
import { HomepageViewComponent } from '../../../homepage/views/homepage-view/homepage-view.component';

@Component({
  selector: 'app-login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.css'],
})
export class LoginViewComponent extends ViewBaseComponent {
  public message: string = "Connexion à l'ERP ...";
  public users: User[] = [];

  constructor(
    private cookieService: CookieService,
    protected ref: ChangeDetectorRef
  ) {
    super(ref);
  }

  async ngOnInit() {
    this.cookieService.set('XDEBUG_SESSION', 'PHPSTORM');
    this.users = CredentialsService.users;
    let userToLogin: User = null;
    let sessionToken = '';

    try {
      // Try to auto login admin in DEV move
      if (!this.isProd) {
        console.log('DEV mode, automatic login...');
        userToLogin = (
          await User.load(['00000000-0000-0072-0000-000000000001'])
        )[0];
        sessionToken = 'dev-session';
      }

      if (!userToLogin) {
        // Try to load user session
        let sessionId: string = this.cookieService.get('PHPSESSID');
        if (sessionId) {
          const sessionResult = await DataService.select({
            definition: {
              database: {
                table: 'usersessions',
                id: 'id',
              },
              values: {
                session: { type: 'string' },
                ip: { type: 'string' },
                UserId: { type: 'string' },
              },
            },
            conditions: `session="${sessionId}"`,
          });

          if (
            sessionResult.result == 'success' &&
            sessionResult.details.length > 0
          ) {
            const sessionToken = sessionResult.details[0]['session'];
            const id_user: string = sessionResult.details[0]['UserId'];
            if (id_user) {
              userToLogin = (await User.load([id_user]))[0];
            }
          }
        }
      }

      if (userToLogin) {
        this.message =
          "Connecté.<br/>Veuillez patienter pendant le chargement de l'ERP...";
        CredentialsService.sessionToken = sessionToken;
        ViewsComponent.openView(HomepageViewComponent);
        await CredentialsService.login(userToLogin);
        sessionStorage.setItem('authToken', sessionToken);
        await waitForInteropAuthenticate();
        window.reactInterop.authenticate(sessionToken);
      } else {
        debugger;
        window.location.href = 'logout.php';
      }
    } catch (err) {
      debugger;
      console.error('Error while trying to login:', err);
      this.message =
        'Erreur lors de la connexion à l\'ERP.<br/><a href="logout.php" class="btn btn-primary">Réessayer</a>';
      document.location.href = 'logout.php';
      console.error(err);
    }
  }
  public get isProd() {
    // return false;
    return environment.production === true;
  }
}
