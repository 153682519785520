import { HomepageViewComponent } from '../../modules/homepage/views/homepage-view/homepage-view.component';
import { Injectable } from '@angular/core';
import { LoadingPromise } from '../../classes/objects/LoadingPromise.class';
import { Merchant } from '../../modules/other-data/classes/Merchant.class';
import { NotificationsComponent } from '../../components/notifications/notifications.component';
import { Permission } from '../../classes/credentials/Permission.class';
import { User } from '../../classes/credentials/User.class';
import { UserProfile } from '../../classes/credentials/UserProfile.class';
import { ViewsComponent } from '../../components/views/views.component';

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  public static sessionToken: string = '';
  public static users: User[] = [];
  public static user_profiles: UserProfile[] = [];
  public static permissions: any = {};

  public static loadPromise: Promise<any> = null;
  public static loggedUser: User = null;
  public static loggedMerchant: Merchant = null;

  constructor() {}

  public static init() {
    return LoadingPromise.create<any>((resolve, reject) => {
      UserProfile.load().then(
        (result) => {
          this.user_profiles = result;
          User.load(null, ['name']).then(
            (result) => {
              this.users = result;
              Permission.load().then(
                (result) => {
                  //this.permissions = result;
                  this.permissions = {};
                  for (let i = 0; i < result.length; ++i) {
                    let perm = result[i];
                    this.permissions[perm.name] = perm;
                  }
                  resolve(true);
                },
                (err) => {
                  NotificationsComponent.push({
                    type: 'error',
                    title: 'Erreur de chargement',
                    summary: 'Impossible de charger les permissions',
                  });
                  console.error(err);
                  reject(err);
                }
              );
            },
            (err) => {
              NotificationsComponent.push({
                type: 'error',
                title: 'Erreur de chargement',
                summary: 'Impossible de charger les utilisateurs',
              });
              console.error(err);
              reject(err);
            }
          );
        },
        (err) => {
          NotificationsComponent.push({
            type: 'error',
            title: 'Erreur de chargement',
            summary: 'Impossible de charger les profils utilisateurs',
          });
          console.error(err);
          reject(err);
        }
      );
    });
  }

  public static async login(user: User) {
    CredentialsService.loggedUser = user;
    const merchants = await CredentialsService.getMerchants();
    if (merchants.length > 0) CredentialsService.loggedMerchant = merchants[0];
    return merchants[0];
  }

  public static logout() {
    CredentialsService.loggedUser = null;
  }

  public static isUserAllowed(permission: string, user: User = null) {
    // if (!permission) return true;
    if (!user) {
      if (!CredentialsService.loggedUser) return false;
      else user = CredentialsService.loggedUser;
    }
    if (user.admin == 1 || user.profile.admin == 1) return true;
    if (!CredentialsService.permissions[permission]) return true;
    let permission_obj: Permission = CredentialsService.permissions[permission];
    if (!permission_obj) return true;
    return (
      permission_obj.allowedUsers.includes(user) ||
      (user.profile && permission_obj.allowedProfiles.includes(user.profile))
    );
  }

  public static getMerchants() {
    return Merchant.load(null, null, null, null, `id_user = '${CredentialsService.loggedUser.id}'`);
  }
}
